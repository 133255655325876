
export class DnService {

    public async getAllDn(data: any): Promise<any> {
        const response = await fetch('/api/dn/' + data);
        return await response.json();
    }

    public async editDn(data: any): Promise<any> {
        const response = await fetch(`/api/dn`, {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({dn: data})
        })
        return await response.json();
    }

}