
export class OsebaService {

    public async getAllOsebe(data: any): Promise<any> {
        const response = await fetch('/api/osebe/' + data);
        return await response.json();
    }

    public async editOseba(data: any): Promise<any> {
        const response = await fetch(`/api/oseba`, {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({oseba: data})
        })
        return await response.json();
    }

    public async resetConfirmations(): Promise<any> {
        const response = await fetch('/api/confirmationReset');
        return await response.json();
    }

}