
export class DoService {

    public async getAllDo(data: any): Promise<any> {
        const response = await fetch('/api/do/' + data);
        return await response.json();
    }

    public async editDn(data: any): Promise<any> {
        const response = await fetch(`/api/do`, {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({do: data})
        })
        return await response.json();
    }

}