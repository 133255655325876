import {Box, Dialog, DialogContent, IconButton, Typography, useTheme} from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import {Close} from "@mui/icons-material";
import React from "react";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import DoneAllIcon from '@mui/icons-material/DoneAll';

interface PodpisnaProps {
    selectedRow: any;
    padRef: any;
    onCancel: () => void;
    saveSignatureCanvas: () => void;
    open: boolean;
}

export default ({selectedRow, padRef, onCancel, saveSignatureCanvas, open}: PodpisnaProps) => {
    const theme = useTheme();

    const clearSignatureCanvas = () => {
        padRef.current.clear();
    }

    const title = selectedRow[0] ? selectedRow[0].oskrbnazsoba1 + ' - ' + selectedRow[0].vredstr1 : " ";

    return <Dialog open={open} onClose={onCancel} fullScreen>

        <DialogContent sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <Typography variant={"h4"}>{title}</Typography>
            <IconButton onClick={onCancel}>
                <Close/>
            </IconButton>
        </DialogContent>

        <DialogContent sx={{
            p: "0 20px",
            display: "grid",
            gridTemplateColumns: "3fr 1fr",
            alignItems: "center"
        }}>
            <Box sx={{height: "200px", border: 1}}>
                <SignatureCanvas
                    ref={padRef}
                    canvasProps={{
                        width: 640,
                        height: 198,
                        className: 'sigPad'
                    }}
                    backgroundColor={"#f8f8f8"}
                />
            </Box>

            <Box sx={{display: "grid", gridTemplateRows: "1fr 1fr"}}>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    {selectedRow.length > 0 &&
                        <IconButton color={"primary"} sx={{width: "3em", height: "3em"}}
                                    onClick={saveSignatureCanvas}>
                            <DoneAllIcon style={{fontSize: 70}}/>
                        </IconButton>
                    }
                </Box>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <IconButton color={"primary"} sx={{width: "3em", height: "3em"}}
                                onClick={clearSignatureCanvas}>
                        <CleaningServicesIcon style={{fontSize: 70}}/>
                    </IconButton>

                    {/*for testing purposes - clear confirmations*/}
                    {/*<IconButton color={"primary"} sx={{width: "5.5em", height: "5.5em"}}*/}
                    {/*            onClick={confirmationsReset}>*/}
                    {/*    <RestartAlt style={{fontSize: 70}}/>*/}
                    {/*</IconButton>*/}
                </Box>
            </Box>

        </DialogContent>
    </Dialog>
}
