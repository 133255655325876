import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createTheme, PaletteColorOptions, ThemeProvider} from "@mui/material";

declare module "@mui/material/styles/createPalette" {
    interface Palette {
        panic: PaletteColorOptions;
    }

    interface PaletteOptions {
        panic: PaletteColorOptions;
    }
}
declare module '@mui/material/Button' {
    export interface ButtonPropsColorOverrides {
        panic: true
    }
}

declare module '@mui/material/SvgIcon' {
    export interface SvgIconPropsColorOverrides {
        panic: true
    }
}

declare module '@mui/material/Typography' {
    export interface TypographyPropsColorOverrides {
        panic: true
    }
}

let theme = createTheme({
    palette: {
        primary: {
            // main: blue[700],
            main: "rgba(6, 74, 158)",
        },
        panic: {
            main: "#000"
        },
        info: {
            // main: blue[700],
            main: "rgba(6, 74, 158)",
        }
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    margin: "0.3em 0"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: "bold"
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    justifyContent: "flex-end"
                }
            }
        },
        MuiInputLabel: {
            defaultProps: {
                sx: {
                    fontSize: "1.5em",
                },
            },
        },
        MuiOutlinedInput: {
            defaultProps: {
                sx: {
                    fontSize: "1.5em",
                }
            }
        },
        MuiTab: {
            defaultProps: {
                sx: {fontSize: "1.5em", mt: "1em"}
            }
        }
    }
});

theme = createTheme(theme, {
    typography: {
        fontFamily: [
            'Roboto',
            'Arial',
            'sans-serif',
        ].join(','),
        h1: {
            // textTransform: "uppercase",
            color: theme.palette.primary.main
        },
        h2: {
            // textTransform: "uppercase",
            color: theme.palette.primary.main
        },
        h3: {
            // textTransform: "uppercase",
            color: theme.palette.primary.main
        },
        h4: {
            // textTransform: "uppercase",
            color: theme.palette.primary.main
        },
        h5: {
            // textTransform: "uppercase",
            color: theme.palette.primary.main
        },
        h6: {
            // textTransform: "uppercase",
            color: theme.palette.primary.main
        }
    },
    components: {
        MuiListItem: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        backgroundColor: theme.palette.primary.main
                    },
                    "&.Mui-selected h1, &.Mui-selected h2, &.Mui-selected h3, &.Mui-selected h4, &.Mui-selected h5, &.Mui-selected .MuiSvgIcon-root": {
                        color: "white"
                    }
                }
            }
        }
    }
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <App/>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
