import React from 'react'
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {Box} from "@mui/material";

type Person = {
    recno: number,
    oskrbnazsoba1: string,
    vredstr1: string,
    dat_dog: string,
    cas_dog: string,
    binpdf: string
}

interface IOsebe {
    osebe: Person[];
    setSelectedRow: (row: any) => void;
}

const columns: GridColDef[] = [
    {
        field: 'recno'
    },
    {
        field: 'oskrbnazsoba1',
        headerName: 'Priimek in ime',
        headerClassName: 'cell-font',
        flex: 350,
        cellClassName: 'cell-font',
        editable: false,
    },
    {
        field: 'vredstr1',
        headerName: 'Storitev',
        headerClassName: 'cell-font',
        flex: 350,
        cellClassName: 'cell-font',
        editable: false,
    },
    {
        field: 'dat_dog',
        headerName: 'Datum',
        headerClassName: 'cell-font',
        flex: 250,
        cellClassName: 'cell-font',
        editable: false,
        renderCell: (params) => {
            return new Intl.DateTimeFormat('sl-SI').format(params.row.dat_dog)
        }
    },
    {
        field: 'cas_dog',
        headerName: "Ura",
        headerClassName: 'cell-font',
        flex: 100,
        cellClassName: 'cell-font',
        renderCell: (params) => {
            return params.row.cas_dog.substring(0, 5)
        }
    }
];

export const Osebe = ({osebe, setSelectedRow}: IOsebe) => {
    return (
        <Box sx={{height: '100%', width: '100%'}}>
            <DataGrid
                columnVisibilityModel={{recno: false}}
                getRowId={(row) => row.recno}
                rows={osebe}
                columns={columns}
                pageSize={2}
                // density={'standard'}
                density={'comfortable'}
                rowsPerPageOptions={[5]}
                experimentalFeatures={{newEditingApi: true}}
                onSelectionModelChange={(ids) => {
                    const selIDs = new Set(ids);
                    const selRow = osebe.filter((row) =>
                        selIDs.has(row.recno),
                    );
                    setSelectedRow(selRow);
                }}
            />
        </Box>
    )
}
