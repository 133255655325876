import React, {useState} from 'react';
import {Alert, Box, Button, Card, Snackbar, Typography} from "@mui/material";
import {useSearchParams} from "react-router-dom";

interface HeaderProps {
    tip: string
    contextTitle?: string | null
    selectedRow?: any
    openPodpis: () => void
}

const Header = ({tip, contextTitle, selectedRow, openPodpis}: HeaderProps) => {
    return (
        <Card sx={{
            display: "flex",
            justifyContent: "space-between",
        }}>

            {/* Logo */}
            <Box sx={{pl: "1em", display: "flex", alignItems: "center"}}>
                <img alt={"Podpisna komponenta"} height={"50em"} style={{objectFit: "contain"}}
                     src={"tabFxLogo.svg"}/>
            </Box>

            {/* Podatki o storitvi + gumb podpiši */}
            {selectedRow.length !== 0 && <Button
                variant={"contained"}
                sx={{
                    textTransform: "none",
                    fontSize: "4vh"
                }}
                onClick={openPodpis}
            >
                Podpiši: {() => {switch (tip) {
                    case "DU": {
                        return selectedRow[0].oskrbnazsoba1 + '-' + selectedRow[0].vredstr1
                        break
                    }
                    case "DN": {
                        return "dn"
                        break
                    }
                    case "DO": {
                        return "dobavnica"
                        break
                    }
                }}}

            </Button>}

            {/* context param */}
            {contextTitle && <Box sx={{pr: "1em", display: "flex", alignItems: "center"}}>
                <Typography variant={"h4"}>{contextTitle}</Typography>
            </Box>}
        </Card>
    )
}

export default Header