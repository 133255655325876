import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import {Osebe} from './Osebe'
import {OsebaService} from '../services/OsebaService'
import {DnService} from '../services/DnService'
import {DoService} from '../services/DoService'
import {Alert, Box, Card, Snackbar} from "@mui/material";
import Header from "./Header";
import PodpisnaDialog from './PodpisnaDialog';
import {useSearchParams} from "react-router-dom";

const Home = () => {
    const [podpisnaOpen, setPodpisnaOpen] = useState(false);

    const [osebe, setOsebe] = useState([]);
    const [dnji, setDnji] = useState([])
    const [dobavnice, setDobavnice] = useState([])

    const osebaService = useMemo(() => new OsebaService(), []);
    const dnService = useMemo(() => new DnService, []);
    const doService = useMemo(() => new DoService, []);
    const padRef = useRef() as React.MutableRefObject<any>;
    const [canvas, setCanvas] = useState<string | undefined>(undefined);

    const [selectedRow, setSelectedRow] = useState<any[]>([])

    const [searchParams] = useSearchParams();
    const tip = searchParams.get("tip")

    const nadstropje = searchParams.get("nadstropje")
    const dnSearch = searchParams.get("dnpogoj")
    const doSearch = searchParams.get("dopogoj")

    const nadstropjeStr = "'" + nadstropje?.split(",").join("','") + "'";
    const dnSearchStr = "'" + dnSearch?.split(",").join("','") + "'";
    const doSearchStr = "'" + doSearch?.split(",").join("','") + "'";

    const [open, setOpen] = useState(!tip);
    const handleMsgClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        switch (tip) {
            case "DU":
                osebaService.getAllOsebe(nadstropjeStr).then(osebe => {
                    setOsebe(osebe)
                });
                break;
            case "DN":
                dnService.getAllDn(dnSearchStr).then(dnji => {
                    setDnji(dnji)
                })
                break;
            case "DO":
                doService.getAllDo(doSearchStr).then(dobavnice => {
                    setDobavnice(dobavnice)
                })
                break
        }
    }, [])

    const saveSignatureCanvas = useCallback(() => {
        const sigImage = padRef?.current.toDataURL();
        setPodpisnaOpen(false)
        setCanvas(sigImage);
        if (selectedRow) {
            switch (tip) {
                case "DU": {
                    selectedRow[0].binpdf = sigImage
                    selectedRow[0].oskrbodssoba3 = 'P'
                    osebaService.editOseba(selectedRow[0]).then(response => {
                        osebaService.getAllOsebe(nadstropjeStr).then(osebe => {
                            setOsebe(osebe)
                        });
                    })
                    break
                }
                case "DN": {
                    // toDo - dn implementation
                    break
                }
                case "DO": {
                    // toDo - dobavnice implementation
                    break
                }
            }
            padRef.current.clear();
            setCanvas(undefined);
        }
    }, [selectedRow, osebaService, dnService, doService]);

    const handlePodpisnaOpen = () => {
        setPodpisnaOpen(true)
    }

    const closePodpisna = () => {
        setPodpisnaOpen(false)
    }

    const signatureRow = (row: any) => {
        setSelectedRow(row)
    }

    // for testing purposes - clear confirmations
    // const confirmationsReset = () => {
    //     osebaService.resetConfirmations().then(response => {
    //         osebaService.getAllOsebe(nadstropjeStr).then(osebe => {
    //             setOsebe(osebe)
    //         });
    //     })
    // };

    return (
        <Box sx={{
            backgroundColor: "#eee",
            display: "grid",
            gridGap: "1em",
            gridTemplateRows: "15vh 75vh",
            p: "1em",
        }}>

            {!tip && <Snackbar open={open} onClose={handleMsgClose} autoHideDuration={5000}
                                        key={new Date().toLocaleString()}>
                <Alert variant={"filled"} severity="error" sx={{width: '100%'}}>
                    Tip ni določen...
                </Alert>
            </Snackbar>}

            {tip == "DU" && <Header tip={tip} contextTitle={nadstropje} selectedRow={selectedRow} openPodpis={handlePodpisnaOpen}></Header>}
            {tip == "DN" && <Header tip={tip} contextTitle={dnSearchStr} selectedRow={selectedRow} openPodpis={handlePodpisnaOpen}></Header>}
            {tip == "DO" && <Header tip={tip} contextTitle={doSearchStr} selectedRow={selectedRow} openPodpis={handlePodpisnaOpen}></Header>}

            <PodpisnaDialog selectedRow={selectedRow} padRef={padRef} open={podpisnaOpen}
                            onCancel={closePodpisna} saveSignatureCanvas={saveSignatureCanvas}/>

            <Card sx={{width: "100%"}}>
                <Osebe osebe={osebe} setSelectedRow={(row) => {
                    signatureRow(row)
                }}></Osebe>
            </Card>

        </Box>
    );
}
export default Home